import clsx from 'clsx'

import { IconProps } from './types'

/**
 * Component containing all the icons.
 *
 * @param name required, icon name
 * @param className useful to change color of an icon – use text color property for that
 */
const Icon: React.FC<IconProps> = ({ name, className = 'text-black' }) => {
  return require(`./assets/${name}.svg`).default({ className: clsx('fill-current', className) })
}

export default Icon
